import React, { useState } from 'react';

const Tools = ({
  chapters,
  metaData,
  setChapters,
  setSelectedChapter,
  setMessages,
  setQueuedTasks,
  summaries,
  setSummaries,
  setPasteText,
  handleClearSummaries,
  handlePasteTextSummarization,
  socket // Added socket prop
}) => {
  const [inputText, setInputText] = useState('');

  const handleClearAllEdits = () => {
    localStorage.clear();
    setChapters(chapters);
    setSummaries({});
    setMessages([{ author: 'System', content: chapters[0].content }]);
    setSelectedChapter(chapters[0].content);
    setQueuedTasks([]);
  };

  const handleCopyChat = () => {
    const chatContent = summaries.map((msg) => `${msg.author}: ${msg.content}`).join('\n');
    navigator.clipboard.writeText(chatContent).then(() => alert('Chat copied to clipboard!'));
  };

  const handleDownloadJSON = () => {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({ metaData, chapters }, null, 2))}`;
    const downloadAnchorElem = document.createElement('a');
    downloadAnchorElem.setAttribute('href', dataStr);
    downloadAnchorElem.setAttribute('download', 'book.json');
    downloadAnchorElem.click();
  };

  const handleSummarizeText = () => {
    if (socket.current && socket.current.readyState === WebSocket.OPEN) {
      // console.log('🟢 Sending bookToSummary:', inputText);
      socket.current.send(
        JSON.stringify({
          mode: 'service',
          service: 'bookToSummary',
          message: { content: inputText }
        })
      );
    } else {
      console.error('🔴 WebSocket is not open. Unable to send bookToSummary.');
    }
  };

  return (
    <div className="tools">
      <h3>Read it or make your own!</h3>
      <button onClick={handleClearAllEdits} className="button clear-button">Clear All</button>
      <button onClick={handleCopyChat} className="button copy-button">Copy Chat</button>
      <button onClick={handleDownloadJSON} className="button download-button">Download JSON</button>
      <button onClick={handleClearSummaries} className="button clear-button">Clear Summaries</button>

      <fieldset>
        <legend>Summarize Custom Text</legend>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter text to summarize..."
          className="input-textarea"
        />
        <button onClick={handleSummarizeText} className="button summarize-button">Summarize Text</button>
      </fieldset>
    </div>
  );
};

export default Tools;

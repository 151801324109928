import React, { useState } from 'react';
import metaDataFile from '../../meta.json';
import chaptersData from '../../book.json';

const ChapterContent = ({
  selectedChapter,
  setSelectedChapter,
  currentChapterIndex,
  isReadMode,
  handlePreviousChapter,
  handleNextChapter,
  handleToggleReadMode,
  handleSaveChapter,
  statusMessage,
  renderStatusMessage,
  newMessage,
  setNewMessage,
  handleKeyDown,
  handleSendMessage
}) => {
  const [isSummaryPopupVisible, setIsSummaryPopupVisible] = useState(false);
  // console.log('metaDataFile', metaDataFile);

  const handleToggleSummaryPopup = () => {
    setIsSummaryPopupVisible(!isSummaryPopupVisible);
  };

  // New function to format and open the chapter in a new tab
  const handleFormatChapter = () => {
    if (selectedChapter) {
      const encodedChapter = encodeURIComponent(`fix the formatting of this chapter, and add more dialogue if you want, pull from memory about this chapter of the book dont respond with anything but the CHAPTER #: <chapter> dont include the chapter title and dont bold the chapter just CHAPTER ###: \n\n text of the chapter, and rewrite the intro to focus on something else in the scene. read the chapter number from the chapter:      ${selectedChapter}`);
      const url = `https://chatgpt.com/?model=gpt-4o-mini#prompt=${encodedChapter}`;
      window.open(url, '_blank'); // Open the URL in a new tab
    } else {
      alert('No chapter selected to format.'); // Alert if no chapter is selected
    }
  };

  if (!selectedChapter) {
    return <div>No chapter selected</div>;
  }

  console.log('chaptersData[currentChapterIndex - 1].audio_url', chaptersData[currentChapterIndex])
  

  return (
    <div className="main-panel chapter-content">
      
      <div className="chapter-controls">
        <div className="title">
          <h1>The First Wall</h1>

          <h2>Chapter {currentChapterIndex}</h2> 


          </div>
          <div className="content-upper">


            {chaptersData[currentChapterIndex - 1].audio_file && (
              <div className="audio-book-section">
                <audio
                  key={chaptersData[currentChapterIndex - 1].audio_file} // Add key to force rerender
                  controls
                >
                  <source
                    src={chaptersData[currentChapterIndex - 1].audio_file}
                    type="audio/wav"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            )}


            <button className="read-mode-button mobile-only" onClick={handleToggleReadMode}>
              {isReadMode ? '[-]' : '[+]'}
            </button>
            <div className="chapter-navigation">
              <button onClick={handlePreviousChapter} disabled={!selectedChapter}>
                Previous
              </button>
              <button onClick={handleNextChapter} disabled={!selectedChapter}>
                Next
              </button>
            </div>
            <button className="desktop-only" onClick={handleSaveChapter}>
              Save
            </button>
            <button className="desktop-only" onClick={handleFormatChapter}>
              Format
            </button>

            <button className="book-summary-button" onClick={handleToggleSummaryPopup}>
            TL;DR
          </button>
        </div>
      </div>
      


      <textarea
        className={`selected-chapter ${isReadMode ? 'read-mode' : ''}`}
        value={selectedChapter || ''} // Ensure safe access to content
        onChange={(e) => setSelectedChapter(e.target.value)}
      />

      {renderStatusMessage && renderStatusMessage()}

      <div className="send-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>

      {statusMessage.text && (
        <div className={`status-message ${statusMessage.type}`}>
          {statusMessage.text}
        </div>
      )}

      {/* Book Summary Popup */}
      {isSummaryPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Book Summary</h2>
            <button onClick={handleToggleSummaryPopup}>Close</button>
            <div>
              <h1>{metaDataFile.book_summary.title}</h1>
              <p>{metaDataFile.book_summary.plot_overview}</p>
              <div>
                {metaDataFile.book_summary.sections.map((section, index) => (
                  <div key={index} style={{ marginBottom: '20px' }}>
                    <h1>{section.title}</h1>
                    <p>{section.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterContent;
